import { Link } from 'gatsby'
import React from 'react'
import route from '../../utils/route'
import styles from './boxStaticPageList.module.scss'

interface BoxImageTitleProps {
  title: string
  img: string
  description: string
  route: string
}

interface Props {
  title: string
  items: BoxImageTitleProps[]
}

export default function BoxStaticPageList({ title, items }: Props) {
  return (
    <React.Fragment>
      <h2>{title}</h2>
      <div className={styles.items_wrapper}>
        {items.map(item => (
          <div key={item.title} className={styles.item}>
            <Link to={route(item.route)}>
              <img alt={`${item.title}`} src={item.img} />
              <h4>{item.title}</h4>
              <span>{item.description}</span>
            </Link>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}
